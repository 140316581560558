import React, { Component } from "react";
import "../App.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import NavDropdown from "react-bootstrap/NavDropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import MediaQuery from "react-responsive";
import $ from "jquery";
import { Link, animateScroll as scroll } from "react-scroll";

class Navb extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    // this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    // this.updateWindowDimensions();
    window.addEventListener("scroll", this.handleScroll);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  handleScroll = () => {
    if (window.innerWidth > 600) {
      if (
        document.body.scrollTop > 150 ||
        document.documentElement.scrollTop > 150
      ) {
        $("#brand").css({
          color: "black",
        });
        $("#logo").css({
          height: "50px",
        });
        $("#nav").css({
          "background-color": "white",
        });
        $(".nav-link1").css({
          color: "black",
        });
        $(".nav-link").css({
          color: "black",
        });
      } else {
        $("#brand").css({
          color: "white",
        });
        $("#nav").css({
          "background-color": "transparent",
        });
        $(".nav-link").css({
          color: "white",
        });
        $(".nav-link1").css({
          color: "white",
        });
        $("#logo").css({
          height: "70px",
          transition: "height 1.8s",
        });
      }
    }
  };
  render() {
    return (
      <div>
        <MediaQuery minDeviceWidth={601}>
          <Navbar className="fixed-top" id="nav">
            <Link
              className="mr-auto"
              // className="nav-link1"
              activeClass="active"
              to="masthead"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              style={{
                color: "white",
                paddingLeft: "7%",
              }}
            >
              <Navbar.Brand
                href="#home"
                id="brand"
                style={{
                  fontSize: "22px",
                  fontWeight: 500,
                  fontFamily: "Merienda, cursive",
                  color: "white",
                  marginTop: "5%",
                }}
              >
                Kimberlie Flowers
              </Navbar.Brand>
            </Link>
            <Nav inline style={{ paddingRight: "5%" }}>
              <Link
                className="nav-link1"
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                style={{
                  color: "white",
                  marginTop: "5%",
                  padding: "10px",
                  fontWeight: 500,
                  fontFamily: "Playfair Display, serif",
                }}
              >
                About
              </Link>
              <Link
                className="nav-link1"
                activeClass="active"
                to="services"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                style={{
                  color: "white",
                  marginTop: "5%",
                  padding: "10px",
                  fontWeight: 500,
                  fontFamily: "Playfair Display, serif",
                }}
              >
                Services
              </Link>
              <Link
                className="nav-link1"
                activeClass="active"
                to="fees"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                style={{
                  color: "white",
                  marginTop: "5%",
                  padding: "10px",
                  fontWeight: 500,
                  fontFamily: "Playfair Display, serif",
                }}
              >
                Fees
              </Link>
              <Link
                className="nav-link1"
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                style={{
                  color: "white",
                  marginTop: "5%",
                  padding: "10px",
                  fontWeight: 500,
                  fontFamily: "Playfair Display, serif",
                }}
              >
                Contact
              </Link>
            </Nav>
          </Navbar>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={600}>
          <Navbar
            expand="lg"
            className="fixed-top"
            id="nav"
            style={{ backgroundColor: "white" }}
          >
            <Navbar.Brand
              href="#home"
              style={{
                fontFamily: "Merienda, cursive",
              }}
            >
              Kimberlie Flowers
            </Navbar.Brand>
            <Navbar.Toggle
              className="navbar-toggler"
              aria-controls="basic-navbar-nav"
              style={{
                border: "none",
                outline: "none",
              }}
              onClick={this.handleClick}
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto" id="collapse">
                <Link
                  className="nav-link1"
                  activeClass="active"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  style={{
                    // color: "",
                    marginTop: "5%",
                    padding: "10px",
                  }}
                >
                  About
                </Link>
                <Link
                  className="nav-link1"
                  activeClass="active"
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  style={{
                    // color: "rgb(224, 74, 144)",
                    marginTop: "5%",
                    padding: "10px",
                  }}
                >
                  Services
                </Link>
                <Link
                  className="nav-link1"
                  activeClass="active"
                  to="fees"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  style={{
                    // color: "rgb(224, 74, 144)",
                    marginTop: "5%",
                    padding: "10px",
                  }}
                >
                  Fees
                </Link>
                <Link
                  className="nav-link1"
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  style={{
                    // color: "rgb(224, 74, 144)",
                    marginTop: "5%",
                    padding: "10px",
                  }}
                >
                  Contact
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </MediaQuery>
      </div>
    );
  }
}

export default Navb;

import React, { Component } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import MediaQuery from "react-responsive";

class About extends Component {
  render() {
    return (
      <div>
        <section
          class="page-section"
          id="services"
          style={{
            backgroundImage: "url(plz.jpg)",
          }}
        >
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8 text-center">
                <h2
                  class="mt-0"
                  id="services"
                  style={{ fontWeight: 500, color: "#183059" }}
                >
                  Areas of Expertise
                </h2>
                <hr
                  class="divider light my-4"
                  style={{ borderColor: "#183059" }}
                />
                <h5
                  class="mb-5"
                  id="serviceHeader"
                  style={{ color: "#183059" }}
                >
                  It's my goal to create a comfortable, safe environment where
                  we will work together to facilitate change that is grounded,
                  soul-led, and lasting.
                </h5>
                <MediaQuery minWidth={601}>
                  <Container>
                    <Row style={{ marginBottom: "2%" }}>
                      <Col id="colStyle">
                        <Container>
                          <Row>
                            <Col xs={2}>
                              <i class="fas fa-check"></i>
                            </Col>
                            <Col id="servicesList">Sports Performance</Col>
                          </Row>
                        </Container>
                      </Col>
                      <Col id="colStyle1">
                        <Container>
                          <Row>
                            <Col xs={2}>
                              <i class="fas fa-check"></i>
                            </Col>
                            <Col id="servicesList">Eco Psychology</Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: "2%" }}>
                      <Col id="colStyle">
                        <Container>
                          <Row>
                            <Col xs={2}>
                              <i class="fas fa-check"></i>
                            </Col>
                            <Col id="servicesList">Wellness Presenter</Col>
                          </Row>
                        </Container>
                      </Col>
                      <Col id="colStyle1">
                        <Container>
                          <Row>
                            <Col xs={2}>
                              <i class="fas fa-check"></i>
                            </Col>
                            <Col id="servicesList">Woman's Issues/Groups</Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: "2%" }}>
                      <Col id="colStyle">
                        {/* <i class="fas fa-check"></i>1 of 2 */}
                        <Container>
                          <Row>
                            <Col xs={2}>
                              <i class="fas fa-check"></i>
                            </Col>
                            <Col id="servicesList">Life Transitions </Col>
                          </Row>
                        </Container>
                      </Col>
                      <Col id="colStyle1">
                        <Container>
                          <Row>
                            <Col xs={2}>
                              <i class="fas fa-check"></i>
                            </Col>
                            <Col id="servicesList">Eating Disorders </Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: "2%" }}>
                      <Col id="colStyle">
                        {/* <i class="fas fa-check"></i>1 of 2 */}
                        <Container>
                          <Row>
                            <Col xs={2}>
                              <i class="fas fa-check"></i>
                            </Col>
                            <Col id="servicesList">Grief/Bereavement </Col>
                          </Row>
                        </Container>
                      </Col>
                      <Col id="colStyle1">
                        <Container>
                          <Row>
                            <Col xs={2}>
                              <i class="fas fa-check"></i>
                            </Col>
                            <Col id="servicesList">PTSD/Trauma </Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: "2%" }}>
                      <Col id="colStyle">
                        {/* <i class="fas fa-check"></i>1 of 2 */}
                        <Container>
                          <Row>
                            <Col xs={2}>
                              <i class="fas fa-check"></i>
                            </Col>
                            <Col id="servicesList">Foster/Adoption </Col>
                          </Row>
                        </Container>
                      </Col>
                      <Col id="colStyle1">
                        <Container>
                          <Row>
                            <Col xs={2}>
                              <i class="fas fa-check"></i>
                            </Col>
                            <Col id="servicesList">Couples </Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: "2%" }}>
                      <Col id="colStyle">
                        {/* <i class="fas fa-check"></i>1 of 2 */}
                        <Container>
                          <Row>
                            <Col xs={2}>
                              <i class="fas fa-check"></i>
                            </Col>
                            <Col id="servicesList">Depression/Anxiety </Col>
                          </Row>
                        </Container>
                      </Col>
                      <Col id="colStyle1">
                        <Container>
                          <Row>
                            <Col xs={2}>
                              <i class="fas fa-check"></i>
                            </Col>
                            <Col id="servicesList">Multiculturalism </Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "2%",
                      }}
                    >
                      <Col id="colStyle">
                        {/* <i class="fas fa-check"></i>1 of 2 */}
                        <Container>
                          <Row>
                            <Col xs={2}>
                              <i class="fas fa-check"></i>
                            </Col>
                            <Col id="servicesList">
                              Critical Incident <br />
                              Stress Management{" "}
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                      <Col id="colStyle1">
                        <Container>
                          <Row>
                            <Col xs={2}>
                              <i class="fas fa-check"></i>
                            </Col>
                            <Col id="servicesList">
                              Adolescents, Teens, Groups{" "}
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  </Container>
                </MediaQuery>
                <MediaQuery maxWidth={600}>
                  <Container>
                    <Row
                      style={{
                        marginBottom: "2%",
                        width: "80%",
                        margin: "0% auto",
                      }}
                    >
                      <Col id="colStyle" style={{ marginBottom: "2%" }}>
                        <i
                          class="fas fa-check"
                          style={{ paddingRight: "2%" }}
                        ></i>
                        Sports Performance
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "2%",
                        width: "80%",
                        margin: "0% auto",
                      }}
                    >
                      <Col id="colStyle" style={{ marginBottom: "2%" }}>
                        <i
                          class="fas fa-check"
                          style={{ paddingRight: "2%" }}
                        ></i>
                        Eco Psychology
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "2%",
                        width: "80%",
                        margin: "0% auto",
                      }}
                    >
                      <Col id="colStyle" style={{ marginBottom: "2%" }}>
                        <i
                          class="fas fa-check"
                          style={{ paddingRight: "2%" }}
                        ></i>
                        Wellness Presenter
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "2%",
                        width: "80%",
                        margin: "0% auto",
                      }}
                    >
                      <Col id="colStyle" style={{ marginBottom: "2%" }}>
                        <i
                          class="fas fa-check"
                          style={{ paddingRight: "2%" }}
                        ></i>
                        Woman's Issues/Groups
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "2%",
                        width: "80%",
                        margin: "0% auto",
                      }}
                    >
                      <Col id="colStyle" style={{ marginBottom: "2%" }}>
                        <i
                          class="fas fa-check"
                          style={{ paddingRight: "2%" }}
                        ></i>
                        Life Transitions
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "2%",
                        width: "80%",
                        margin: "0% auto",
                      }}
                    >
                      <Col id="colStyle" style={{ marginBottom: "2%" }}>
                        <i
                          class="fas fa-check"
                          style={{ paddingRight: "2%" }}
                        ></i>
                        Eating Disorders
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "2%",
                        width: "80%",
                        margin: "0% auto",
                      }}
                    >
                      <Col id="colStyle" style={{ marginBottom: "2%" }}>
                        <i
                          class="fas fa-check"
                          style={{ paddingRight: "2%" }}
                        ></i>
                        PTSD/Trauma
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "2%",
                        width: "80%",
                        margin: "0% auto",
                      }}
                    >
                      <Col id="colStyle" style={{ marginBottom: "2%" }}>
                        <i
                          class="fas fa-check"
                          style={{ paddingRight: "2%" }}
                        ></i>
                        Foster/Adoption
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "2%",
                        width: "80%",
                        margin: "0% auto",
                      }}
                    >
                      <Col id="colStyle" style={{ marginBottom: "2%" }}>
                        <i
                          class="fas fa-check"
                          style={{ paddingRight: "2%" }}
                        ></i>
                        Couples
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "2%",
                        width: "80%",
                        margin: "0% auto",
                      }}
                    >
                      <Col id="colStyle" style={{ marginBottom: "2%" }}>
                        <i
                          class="fas fa-check"
                          style={{ paddingRight: "2%" }}
                        ></i>
                        Depression/Anxiety
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "2%",
                        width: "80%",
                        margin: "0% auto",
                      }}
                    >
                      <Col id="colStyle" style={{ marginBottom: "2%" }}>
                        <i
                          class="fas fa-check"
                          style={{ paddingRight: "2%" }}
                        ></i>
                        Multiculturalism
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "2%",
                        width: "80%",
                        margin: "0% auto",
                      }}
                    >
                      <Col id="colStyle" style={{ marginBottom: "2%" }}>
                        <i
                          class="fas fa-check"
                          style={{ paddingRight: "2%" }}
                        ></i>
                        Critical Incident Stress Management
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "2%",
                        width: "80%",
                        margin: "0% auto",
                      }}
                    >
                      <Col id="colStyle" style={{ marginBottom: "2%" }}>
                        <i
                          class="fas fa-check"
                          style={{ paddingRight: "2%" }}
                        ></i>
                        Adolescents, Teens, Groups
                      </Col>
                    </Row>
                  </Container>
                </MediaQuery>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default About;

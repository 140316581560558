import React, { Component } from "react";
// import { Helmet } from "react-helmet";
import "../App.css";

class Contact extends Component {
  render() {
    return (
      <section
        class="page-section"
        id="contact"
        style={{ backgroundColor: "#0E4D92" }}
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 text-center">
              {/* <h2 class="mt-0" style={{ color: "white" }}>
                Let's Get In Touch!
              </h2> */}
              <h2
                class="mt-0"
                id="paytitle2"
                style={{ fontWeight: 500 }}
                // style={{ fontFamily: "Nothing You Could Do, cursive" }}
              >
                Contact Me!
              </h2>
              <hr class="divider my-4" style={{ borderColor: "lightblue" }} />
              <h5
                class="text-white-75 mb-2"
                style={{
                  color: "rgba(255, 255, 255, 0.75)",
                  fontFamily: "Playfair Display, serif",
                  fontWeight: 600,
                }}
              >
                KIMBERLIE FLOWERS, MS, LMFT 39041
              </h5>
              <h5
                class="text-white-75 mb-4"
                style={{
                  color: "rgba(255, 255, 255, 0.75)",
                  fontFamily: "Playfair Display, serif",
                  fontWeight: 600,
                }}
              >
                MAILING:
                <br /> PO BOX 8925, TRUCKEE, CA 96161.
              </h5>
              <h5
                class="text-white-75 mb-5"
                style={{
                  color: "rgba(255, 255, 255, 0.75)",
                  fontFamily: "Playfair Display, serif",
                  fontWeight: 600,
                }}
              >
                PRACTICE LOCATIONS: <br />
                1029 H Street #402, Sacramento CA, 95814
                <br />
                10363 High Street, Truckee CA, 96161
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
              <span style={{ color: "white" }}>
                <i class="fas fa-phone fa-3x mb-3"></i>
              </span>
              <h4
                style={{
                  color: "rgba(255, 255, 255, 0.75)",
                  marginTop: "11px",
                  fontFamily: "Playfair Display, serif",
                  fontWeight: 700,
                }}
              >
                +1 (530) 587-6941
              </h4>
            </div>
            <div class="col-lg-4 mr-auto text-center mb-5 mb-lg-0">
              <span style={{ color: "white" }}>
                <i class="fas fa-envelope fa-3x mb-3"></i>
                <br />
              </span>
              {/* <br /> */}
              <a
                // class="text-white-100 mb-5"
                style={{
                  color: "rgba(255, 255, 255, 0.75)",
                  fontSize: "24px",
                  padding: "13px",
                  fontFamily: "Playfair Display, serif",
                  fontWeight: 700,
                }}
                href="mailto:kimberlie_flowers@yahoo.com"
              >
                kimberlie_flowers@yahoo.com
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;

import React, { Component } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MediaQuery from "react-responsive";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

class Therapy extends Component {
  render() {
    return (
      <div style={{ marginBottom: "5%" }} id="fees">
        <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
          <h2 class="mt-0" id="payTitle" style={{ fontWeight: 500 }}>
            Fees and Rates
          </h2>
          <hr
            class="divider light my-4"
            style={{ borderColor: "rgb(24, 48, 89)" }}
          />
        </div>
        <MediaQuery minWidth={1000}>
          <div class="container">
            <div class="card-deck mb-1 text-center">
              <div
                class="card mb-4"
                style={{ boxShadow: "5px 10px 20px rgb(172, 191, 197)" }}
              >
                <div class="card-header">
                  <h4 class="my-0" id="priceTitle">
                    Individual
                  </h4>
                </div>
                <div class="card-body">
                  <h1 class="card-title pricing-card-title">
                    $150 <small class="text-muted">/ Session</small>
                  </h1>
                  <ul class="list-unstyled mt-3 mb-4" id="priceDesc">
                    <li>Office visits</li>
                    <li>Telehealth</li>
                    <li>
                      some insurance plans <br /> accepted
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="card mb-4"
                style={{ boxShadow: "5px 10px 20px rgb(172, 191, 197)" }}
              >
                <div class="card-header">
                  <h4 class="my-0" id="priceTitle">
                    Group
                  </h4>
                </div>
                <div class="card-body">
                  <h1 class="card-title pricing-card-title">
                    $60 <small class="text-muted">/ Session</small>
                  </h1>
                  <ul class="list-unstyled mt-3 mb-4" id="priceDesc">
                    <li>6 week commitment</li>
                    <li>Adolescents</li>
                    <li>Teens</li>
                    <li>Womens</li>
                  </ul>
                </div>
              </div>
              <div
                class="card mb-4"
                style={{ boxShadow: "5px 10px 20px rgb(172, 191, 197)" }}
              >
                <div class="card-header">
                  <h4 class="my-0" id="priceTitle">
                    Eco-Therapy
                  </h4>
                </div>
                <div class="card-body">
                  <h1 class="card-title pricing-card-title">
                    $250 <small class="text-muted">/ Session</small>
                  </h1>
                  <ul class="list-unstyled mt-3 mb-4" id="priceDesc">
                    <li>2 hour sessions</li>
                    <li>Walk n Talk</li>
                    <li>Equine Therapy</li>
                    <li>SUP Therapy</li>
                    <li>Cycle Therapy/Skiology</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={999}>
          <Container>
            <Row>
              <Col xs={12}>
                <div
                  class="card mb-4"
                  style={{ boxShadow: "5px 10px 20px rgb(172, 191, 197)" }}
                >
                  <div class="card-header">
                    <h4 class="my-0" id="priceTitle">
                      Individual
                    </h4>
                  </div>
                  <div class="card-body">
                    <h1 class="card-title pricing-card-title">
                      $150 <small class="text-muted">/ Session</small>
                    </h1>
                    <ul class="list-unstyled mt-3 mb-4" id="priceDesc">
                      <li>Office visits</li>
                      <li>Telehealth</li>
                      <li>
                        some insurance plans <br /> accepted
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div
                  class="card mb-4"
                  style={{ boxShadow: "5px 10px 20px rgb(172, 191, 197)" }}
                >
                  <div class="card-header">
                    <h4 class="my-0" id="priceTitle">
                      Group
                    </h4>
                  </div>
                  <div class="card-body">
                    <h1 class="card-title pricing-card-title">
                      $60 <small class="text-muted">/ Session</small>
                    </h1>
                    <ul class="list-unstyled mt-3 mb-4" id="priceDesc">
                      <li>6 week commitment</li>
                      <li>Adolescents</li>
                      <li>Teens</li>
                      <li>Womens</li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div
                  class="card mb-4"
                  style={{ boxShadow: "5px 10px 20px rgb(172, 191, 197)" }}
                >
                  <div class="card-header">
                    <h4 class="my-0" id="priceTitle">
                      Eco-Therapy
                    </h4>
                  </div>
                  <div class="card-body">
                    <h1 class="card-title pricing-card-title">
                      $250 <small class="text-muted">/ Session</small>
                    </h1>
                    <ul class="list-unstyled mt-3 mb-4" id="priceDesc">
                      <li>2 hour sessions</li>
                      <li>Walk n Talk</li>
                      <li>Equine Therapy</li>
                      <li>SUP Therapy</li>
                      <li>Cycle Therapy/Skiology</li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          {/* <div class="container" style={{ width: "100%" }}>
            <div class="card-deck mb-3 text-center">
              <div
                class="card mb-4"
                style={{ boxShadow: "5px 10px 20px rgb(172, 191, 197)" }}
              >
                <div class="card-header">
                  <h4 class="my-0" id="priceTitle">
                    Individual
                  </h4>
                </div>
                <div class="card-body">
                  <h1 class="card-title pricing-card-title">
                    $150 <small class="text-muted">/ Session</small>
                  </h1>
                  <ul class="list-unstyled mt-3 mb-4" id="priceDesc">
                    <li>Office visits</li>
                    <li>Telehealth</li>
                    <li>
                      some insurance plans <br /> accepted
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="card mb-4"
                style={{ boxShadow: "5px 10px 20px rgb(172, 191, 197)" }}
              >
                <div class="card-header">
                  <h4 class="my-0" id="priceTitle">
                    Group
                  </h4>
                </div>
                <div class="card-body">
                  <h1 class="card-title pricing-card-title">
                    $60 <small class="text-muted">/ Session</small>
                  </h1>
                  <ul class="list-unstyled mt-3 mb-4" id="priceDesc">
                    <li>6 week commitment</li>
                    <li>Adolescents</li>
                    <li>Teens</li>
                    <li>Womens</li>
                  </ul>
                </div>
              </div>
              <div
                class="card mb-4"
                style={{ boxShadow: "5px 10px 20px rgb(172, 191, 197)" }}
              >
                <div class="card-header">
                  <h4 class="my-0" id="priceTitle">
                    Eco-Therapy
                  </h4>
                </div>
                <div class="card-body">
                  <h1 class="card-title pricing-card-title">
                    $250 <small class="text-muted">/ Session</small>
                  </h1>
                  <ul class="list-unstyled mt-3 mb-4" id="priceDesc">
                    <li>2 hour sessions</li>
                    <li>Walk n Talk</li>
                    <li>Equine Therapy</li>
                    <li>SUP Therapy</li>
                    <li>Cycle Therapy/Skiology</li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
        </MediaQuery>
      </div>
    );
  }
}

export default Therapy;

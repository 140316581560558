import React, { Component } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import MediaQuery from "react-responsive";

class Sections extends Component {
  render() {
    return (
      <div
        class="container-fluid"
        style={{
          marginBottom: "8%",
          marginTop: "18%",
          paddingRight: "10%",
          paddingLeft: "10%",
        }}
        id="about"
      >
        <div class="row justify-content-between">
          <div class="col-lg-5" style={{ paddingLeft: "8%" }} id="col-top">
            <div class="about-descr">
              <div id="title" style={{ textTransform: "uppercase" }}>
                Kimberlie Flowers, MS
                <hr id="titleDivider" align="left" width="80%" />
              </div>
              <div id="spanStyle">Licensed Marriage Family Therapist</div>
              <div id="restStyle">
                Kimberlie Flowers is an entrepreneur, mother, extreme athlete,
                lover of land, teacher, world traveler and Licensed Marriage and
                Family Therapist.{" "}
                <span style={{ fontWeight: 600 }}>
                  As a warm and engaging therapist filled with wonder, vitality
                  and humor, she helps transform painful struggles into
                  enlightened and empowered change to live audaciously.
                </span>
              </div>
              <div
                style={{
                  fontWeight: 600,
                  fontStyle: "italic",
                  borderLeft: "2px solid #bcd7f3",
                  paddingLeft: "15px",
                }}
                id="restStyle3"
              >
                Audacious- extremely bold, daring, fearless, original, intrepid,
                courageous, valiant, lively.
              </div>
              <div id="restStyle">
                With a deep reverence for culture and its richness, Kimberlie
                draws upon her own unique personal story, years of being the
                world’s traveling student, and over 30 years in the mental
                health field (schools, residential treatment, research, dual
                diagnosis programs, Critical Incident/Stress Management, Indian
                tribes, state and federal institutions, corporations, Asian and
                other non profit organizations) and over 25 years in private
                practice to serve as a very eclectic and dynamic
                psychotherapist.
              </div>
            </div>
          </div>
          <div
            class="col-lg-6"
            style={{ width: "90%", marginTop: "5%" }}
            id="marginImg"
          >
            <div class="div-img-bg">
              <div class="about-img">
                <img
                  src="IMG_0017.jpg"
                  class="img-fluid"
                  alt="me"
                  id="changeImg"
                />
              </div>
            </div>
            <span id="spanStyle" style={{ paddingTop: "20px" }}>
              EMPOWER. TRANSFORM. INSPIRE.{" "}
            </span>
          </div>
          <div
            class="col-lg-12"
            id="restStyle4"
            style={{
              paddingRight: "8%",
              paddingLeft: "8%",
              paddingTop: "2%",
            }}
          >
            <span style={{ fontWeight: 600 }}>
              Kimberlie helps clients transform their lives by: overcoming
              trauma; growing insight into corrective behavior; unlocking their
              inner genius; manifesting greater success; creating mindfulness,
              peace and more loving and authentic relationships; cultivating
              more creative power, discernment and commitment to living
              audaciously.{" "}
            </span>
          </div>
          <div
            class="col-lg-12"
            id="restStyle"
            style={{
              paddingRight: "8%",
              paddingLeft: "8%",
              paddingTop: "2%",
            }}
          >
            {" "}
            She holds two Bachelor of Arts degrees in Psychology and History and
            two Master of Science degrees in Marriage, Family and Child
            Counseling and School Counseling. She has worked with a wide range
            of clients which include: adults, adolescents, couples, groups,
            athletes on issues including: athletic performance mental toughness;
            trauma; loss and recovery; eating disorders; relationship
            enrichment; life transitions; self-esteem; leadership and
            empowerment; multiculturalism; and depression, anxiety and stress
            reduction and work/life balance. Kimberlie also specializes in
            Sports Performance and Nature/Eco Psychology and facilitates
            corporate Wellness seminars, leads Nature Therapy healing walks,
            facilitates groups for adolescents and women, and conducts Mental
            Skills training for competitors/ sports performance.
          </div>
        </div>{" "}
        <div class="row justify-content-between">
          <div
            class="col-lg-6"
            style={{ width: "90%", marginTop: "5%" }}
            id="marginImg"
          >
            <div class="div-img-bg">
              <div class="about-img">
                <img
                  src="Screenshot 2020-04-07 at 4.42.35 PM.png"
                  class="img-fluid"
                  alt="me"
                  id="changeImg2"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-5" id="col-top">
            <div class="about-descr">
              <div id="title">
                My Story
                <hr id="titleDivider" align="left" width="80%" />
              </div>
              {/* <div id="spanStyle">Licensed Marriage Family Therapist</div> */}
              <div id="restStyle">
                I am a first generation Japanese- American only child raised in
                the ethnic neighborhood of Echo Park, Los Angeles and lived with
                poverty, neglect and abuse. My parents died in traumatic
                accidents when I was very young and I was then raised by my sole
                relative, grandmother and a foster family at age 6. From there,
                I was brought up in a strict religious, military, law
                enforcement family and have a unique dualistic perspective seen
                thru these two contrasting lenses.
              </div>
              <div id="restStyle">
                My history is complex and rich as I have had a vast collegiate
                and professional athletic career, grew up as a National Park
                brat raising and riding NPS horses and being immersed in nature.
                I have been a wilderness and river guide, teacher and coach, and
                have traveled extensively to over 55 countries as well as the
                United States.{" "}
                <span style={{ fontWeight: 600 }}>
                  {" "}
                  I live by being attuned to my curiosity, epiphanel spiritual
                  journey, and childhood calling.
                </span>
              </div>
            </div>
          </div>
          <div
            class="col-lg-12"
            id="restStyle4"
            style={{
              paddingRight: "8%",
              paddingLeft: "8%",
              paddingTop: "2%",
            }}
          >
            I help transform trauma and undesirable circumstances to create{" "}
            <span style={{ fontWeight: 600 }}>
              effective and thriving functioning, strength of character,
              maturity, emotional balance, socio-emotional intelligence, life
              satisfaction, and resilience. I chose a growth mindset of
              consciously choosing the path that leads to more meaning, depth,
              and purpose{" "}
            </span>{" "}
            while offering a dynamic and unique exploration using Psychodynamic,
            Humanistic, Strength-based, Cognitive Behavioral approaches to
            Psychotherapy, Ecopsychology, and Sports Performance.
          </div>
          <div
            class="col-lg-12"
            id="restStyle"
            style={{
              paddingRight: "8%",
              paddingLeft: "8%",
              paddingTop: "2%",
            }}
          >
            <span style={{ fontWeight: 600 }}>
              I am holistic in my belief that Love, Life, Work, and Spirituality
              are not separate.{" "}
            </span>{" "}
            I love being with my two sons and passionate living in Lake Tahoe,
            Sierra Foothills, Baja Mexico and Red Rock Desert. I am an avid
            enthusiast of Alpine and Nordic Skiing, Cycling and Mountain Biking,
            Volleyball, Paddling, Swimming, Fitness, Tennis, Kiting, Surfing,
            Archery, Animals, Dancing, Cooking, Gardening, Creative Projects,
            Costuming, Philanthropy and Living Audaciously.
          </div>
        </div>
      </div>
    );
  }
}

export default Sections;

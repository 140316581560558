import React, { Component } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

class Practice extends Component {
  render() {
    return (
      <div>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="p-5">
                <img
                  class="img-fluid rounded-circle"
                  src="newsandra.jpg"
                  alt=""
                  style={{ boxShadow: "5px 10px 20px rgb(172, 191, 197)" }}
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="p-5">
                <div id="head3">
                  Esprit de Corps
                  <hr id="titleDivider" align="left" width="80%" />
                </div>
                {/* <div id="title">
                  We salute you!
                  <hr id="titleDivider" align="left" width="80%" />
                </div> */}
                <div id="head2" style={{ color: "#475657" }}>
                  We can tap into our own healing guidance, creative power and
                  mental fortitude to heal. For many, deep connection to their
                  Selves, Others, the Divine and Nature is missing and creating
                  despair, anxiety, and angst. Contentment is found in moments
                  of connection, happiness, and gratitude that can be strung
                  together and made more abundant for the greater good. I call
                  this the Esprit de Corps~a common spirit of collaboration,
                  truth, enthusiasm, and purpose that energizes us.
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-lg-6 order-lg-2">
              <div class="p-5">
                <img
                  class="img-fluid rounded-circle"
                  src="adam1.jpg"
                  alt=""
                  style={{ boxShadow: "5px 10px 20px rgb(172, 191, 197)" }}
                />
              </div>
            </div>
            <div class="col-lg-6 order-lg-1">
              <div class="p-5">
                <div id="head3">
                  Life as a Spiral
                  <hr id="titleDivider" align="left" width="80%" />
                </div>
                <div id="head2" style={{ color: "#475657" }}>
                  The path of life is not a straight line but rather, a spiral.
                  Evolving Souls keep coming back to the same lessons and themes
                  that we thought we already understood to unlock deeper truths.
                  Having to revisit these can be frustrating and excruciating
                  lessons and requires a commitment to self-awareness and
                  discernment to work thru this process. Life begins at our
                  heart center, protected, nurtured, and flowing in its never
                  ending personal journey. Think of the phrases: “spiraling out
                  of control”, “spun out”, or “going in circles”. It is
                  important to allow life to flow and surrender to a heart
                  centered- spiraled life. From the inside-out, we can share
                  with others and shine our grace and nature on the world. From
                  the outside-in, we revisit our thoughts, emotions, patterns,
                  and life lessons. By leveling up or down the spiral, we can
                  release dis-ease, doubt, disharmony, hopelessness, and anxiety
                  and connect again to our deepest quintessential nature and
                  healing.
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="p-5">
                <img
                  class="img-fluid rounded-circle"
                  src="denoz.jpg"
                  alt=""
                  style={{ boxShadow: "5px 10px 20px rgb(172, 191, 197)" }}
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="p-5">
                <div c id="head3">
                  Directed Guidance
                  <hr id="titleDivider" align="left" width="80%" />
                </div>
                <div id="head2" style={{ color: "#475657" }}>
                  Sometimes we come to crossroads and unknown, auspicious
                  terrain in our lives and we may stumble upon a cairn that lets
                  us know we are not alone and that some other sojourner has
                  been here before and pioneered this unfamiliar territory. The
                  cairn is a symbol of balance, direction, safety, friendship,
                  and trust. We may need a guide with compassionate connection
                  and intuition to help guide our journey of faith, hope, and
                  purpose. I can not take the journey for you but I can help
                  carry the burden, instill resilience and guide you by sharing
                  wisdom and my voice on your unique transformational journey.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Practice;

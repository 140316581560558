import React, { Component } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MediaQuery from "react-responsive";

class Landing extends Component {
  render() {
    return (
      <div class="masthead">
        <img
          src="nick-panagou-nDa61av1wbc-unsplash.jpg"
          alt="idk"
          style={{
            maxWidth: "none !important",
            border: "none",
            objectFit: "cover",
            backgroundSize: "cover",
            opacity: "1",
            width: "100%",
            height: "100%",
            position: "absolute",
            left: "0px",
            top: "0%",
            display: "block"
          }}
        ></img>
        <MediaQuery maxDeviceWidth={900}>
          <div
            class="container-fluid h-75"
            style={{
              // padding: "10%",
              marginTop: "3%"
            }}
          >
            <div
              class="row h-100 align-items-center justify-content-center text-center"
              style={{ marginTop: "17%" }}
            >
              <div class="col-lg-10 align-self-end" style={{ padding: "10%" }}>
                <div id="mastText" class="text-uppercase font-weight-bold">
                  Holistic Psychotherapy
                </div>
                <div id="mastText2" class="">
                  Live, Love, Serve, Play Audaciously!
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={901}>
          <div
            class="container-fluid h-100"
            style={
              {
                // padding: "10%",
                // marginTop: "3%"
              }
            }
          >
            <div class="row h-100 align-items-center justify-content-center text-center">
              <div class="col-lg-10 align-self-end" style={{ padding: "10%" }}>
                <div id="mastText" class="text-uppercase font-weight-bold">
                  Holistic Psychotherapy
                </div>
                <div id="mastText2" class="">
                  Live, Love, Serve, Play Audaciously!
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default Landing;

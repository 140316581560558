import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Nav from "./Components/Nav";
import "bootstrap/dist/css/bootstrap.min.css";
import Landing from "./Components/Landing";
import Sections from "./Components/Sections";
import Therapy from "./Components/Therapy";
import About from "./Components/About";
import Practice from "./Components/Practice";
import Contact from "./Components/Contact";

function App() {
  return (
    <div className="App">
      <Nav />
      <Landing />
      <Sections />
      <About />
      {/* <Practice /> */}
      <Practice />
      <Therapy />
      <Contact />
    </div>
  );
}

export default App;
